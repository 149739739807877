import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";

import flecha from "../../images/FlechaBoton1.svg";
import acceso from "../../images/LogoAcceso.png";
import cometa from "../../images/cometa.png";
import creatura from "../../images/creatura-logos_horizontal.png";
import inspiratorio from "../../images/inspiratorio.png";

import { SubContainerA } from "../atoms/containers";
import { ServiceTitle, ServicesPageWrapper, ServicesTitleA } from "../atoms/wrappers";
import { ServicesH1 } from "../atoms/h1";
import { PromoteBar, ServicesPageBar } from "../atoms/bars";
import { ServicesCardSteps } from "../atoms/cards";
import { ActiveNumber, DisabledNumber } from "../atoms/p";
import { ServicesH2A1, ServicesH2C } from "../atoms/h2";
import { SendButton, SendButton1, ServicesLinkA } from "../atoms/buttons";
import { Logos } from "../atoms/img";
import { ServicesOl, ServicesOl2 } from "../atoms/ul";

const ServicesPage = () => {
  const [isActive, setIsActive] = useState(1);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  useEffect(() => {
    switch (query.get("servicio")) {
      case "comunicaciones_estrategicas":
        setIsActive(1);
        break;
      case "cumplimiento_legal":
        setIsActive(2);
        break;
      case "pensamiento_estrategico":
        setIsActive(3);
        break;
      case "proteccion_integral":
        setIsActive(4);
        break;
      default:
        setIsActive(1);
        break;
    }
  }, [setIsActive, query]);

  return (
    <SubContainerA>
      <ServiceTitle>
        <ServicesH1>SERVICIOS</ServicesH1>
        <PromoteBar />
      </ServiceTitle>
      <ServicesPageWrapper>
        <ServicesCardSteps
          onClick={() => {
            setIsActive(1);
            window.scroll({
              top: 650,
              left: 0,
              behavior: 'smooth'
            })
          }}
        >
          {isActive === 1 ? (
            <ActiveNumber>1</ActiveNumber>
          ) : (
            <DisabledNumber>1</DisabledNumber>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "2rem",
            }}
          >
            <img
              src={inspiratorio}
              alt="logo-inspiratorio"
              style={{ width: "10rem" }}
            />
            <ServicesH2A1
              style={isActive === 1 ? { color: "black" } : { color: "#a8a8a8" }}
            >
              COMUNICACIONES ESTRATÉGICAS Y PODER NARRATIVO
            </ServicesH2A1>
            {isActive === 1 ? <ServicesPageBar /> : ""}
          </div>
        </ServicesCardSteps>
        <ServicesCardSteps
          onClick={() => {
            setIsActive(2);
            window.scroll({
              top: 650,
              left: 0,
              behavior: 'smooth'
            })
          }}
        >
          {isActive === 2 ? (
            <ActiveNumber>2</ActiveNumber>
          ) : (
            <DisabledNumber>2</DisabledNumber>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "2rem",
            }}
          >
            <img
              src={creatura}
              alt="logo-creatura"
              style={{ width: "10rem" }}
            />
            <ServicesH2A1
              style={isActive === 2 ? { color: "black" } : { color: "#a8a8a8" }}
            >
              CUMPLIMIENTO LEGAL
            </ServicesH2A1>
            {isActive === 2 ? <ServicesPageBar /> : ""}
          </div>
        </ServicesCardSteps>
        <ServicesCardSteps
          onClick={() => {
            setIsActive(3);
            window.scroll({
              top: 650,
              left: 0,
              behavior: 'smooth'
            })
          }}
        >
          {isActive === 3 ? (
            <ActiveNumber>3</ActiveNumber>
          ) : (
            <DisabledNumber>3</DisabledNumber>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "2rem",
            }}
          >
            <img src={cometa} alt="logo-cometa" style={{ width: "10rem" }} />
            <ServicesH2A1
              style={isActive === 3 ? { color: "black" } : { color: "#a8a8a8" }}
            >
              PENSAMIENTO ESTRATÉGICO Y RESILIENCIA FINANCIERA
            </ServicesH2A1>
            {isActive === 3 ? <ServicesPageBar /> : ""}
          </div>
        </ServicesCardSteps>
        <ServicesCardSteps
          onClick={() => {
            setIsActive(4);
            window.scroll({
              top: 650,
              left: 0,
              behavior: 'smooth'
            })
          }}
        >
          {isActive === 4 ? (
            <ActiveNumber>4</ActiveNumber>
          ) : (
            <DisabledNumber>4</DisabledNumber>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "2rem",
            }}
          >
            <img src={acceso} alt="logo-acceso" style={{ width: "10rem" }} />
            <ServicesH2A1
              style={isActive === 4 ? { color: "black" } : { color: "#a8a8a8" }}
            >
              PROTECCIÓN INTEGRAL
            </ServicesH2A1>
            {isActive === 4 ? <ServicesPageBar /> : ""}
          </div>
        </ServicesCardSteps>
      </ServicesPageWrapper>
      <SendButton1>
        <ServicesLinkA to={`/solicitud_de_servicios${isActive ? `?refererID=${isActive}` : ''}`}>
          SOLICITUD DE SERVICIOS
          <img src={flecha} alt="Enviar Solicitud" />
        </ServicesLinkA>
      </SendButton1>
      <ServicesTitleA>
        {isActive === 1 && (
          <AnimationOnScroll animateIn="animate__fadeInRightBig">
            <a href="https://www.inspiratorio.org/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
              <p>https://www.inspiratorio.org</p>
              <Logos
                src={inspiratorio}
                alt="logo-inspiratorio"
              />
            </a>
          </AnimationOnScroll>
        )}
        {isActive === 2 && (
          <AnimationOnScroll animateIn="animate__fadeInLeftBig">
            <a href="https://creatura.mx" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
              <p>https://creatura.mx</p>
              <Logos
                src={creatura}
                alt="logo-creatura"
              />
            </a>
          </AnimationOnScroll>
        )}
        {isActive === 3 && (
          <AnimationOnScroll animateIn="animate__fadeInRightBig">
            <a href="https://www.colectivometa.com" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
              <p>https://www.colectivometa.com</p>
              <Logos src={cometa} alt="logo-cometa"/>
            </a>
          </AnimationOnScroll>
        )}
        {isActive === 4 && (
          <AnimationOnScroll animateIn="animate__fadeInLeftBig">
            <a href="https://www.acceso.or.cr" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
              <p>https://www.acceso.or.cr</p>
              <Logos src={acceso} alt="logo-acceso"/>
            </a>
          </AnimationOnScroll>
        )}
        <ServicesPageBar />
      </ServicesTitleA>
      {isActive === 1 && (
        <AnimationOnScroll animateIn="animate__fadeInLeftBig">
          <ServiceTitle>
            <ServicesH2C>
              Ofrecemos servicios de comunicación estratégica y fortalecimiento
              del poder narrativo colectivo. La comunicación estratégica es
              aquella que empieza tomando conciencia del objetivo que se busca
              lograr; identifica las audiencias con quienes hay que trabajar
              para lograrlo, las estudia, y a partir de su comprensión,
              determina los mensajes, mensajeros y canales que son persuasivos
              para ellas. El poder narrativo es la capacidad colectiva de pensar
              y utilizar las historias, los mensajes y los relatos de forma
              intencional -combinando la teoría y la práctica narrativa- para
              llevar a las personas hacia las narrativas, los valores y las
              visiones del mundo que queremos promover.
            </ServicesH2C>
            <ServicesH2C>
              Sabemos que para que nuestros movimientos sean exitosos, debemos
              activar audiencias simpatizantes, atraer audiencias persuasibles y
              neutralizar audiencias antagonistas. Nuestros servicios de
              comunicaciones buscan aumentar las capacidades de las
              organizaciones para lograr estos tres objetivos. Para ello,
              ofrecemos servicios de{" "}
              <b>formación, investigación y experimentación narrativa</b> que
              ponen en práctica el nuevo paradigma de las comunicaciones en
              derechos humanos.
            </ServicesH2C>
            <ServicesH2C>
              Apostamos por ofrecer servicios colectivos, con lógicas de
              economía de escala, de forma que la mayor cantidad de
              organizaciones puedan beneficiarse. En los servicios colectivos
              con cupo limitado,{" "}
              <b>
                reservaremos el 40% de los cupos para organizaciones de México y
                Centroamérica
              </b>
              . Adicionalmente, hacemos convocatorias para ofrecer becas de
              experimentación narrativa y acompañamientos individuales dirigidos
              a las organizaciones que ya se han formado en el nuevo paradigma
              de las comunicaciones.
            </ServicesH2C>
            <ServicesOl>
              <ServicesH2C>
                <b>Servicios colectivos:</b>{" "}
              </ServicesH2C>
              <li>
                <ol>
                  <b>1. Formación:</b> Ofrecemos varios tipos de formaciones
                  completamente online virtuales o en línea sobre el Nuevo
                  Paradigma de las Comunicaciones.
                  <li>
                    <b>Experiencia CommsSessions:</b> Esta es la formación
                    básica. A través de webinarios, expertas de nuestro equipo
                    enseñarán sobre el Nuevo Paradigma de las Comunicaciones.
                    (Cupos ilimitados)
                  </li>
                  <li>
                    <b>Experiencia CommsLab:</b> Esta es una formación aplicada.
                    Sumada a CommsSessions, esta experiencia enseñará a diseñar
                    una estrategia de experimentación narrativa basada en una
                    investigación de audiencias y una investigación digital, que
                    diseñaremos específicamente para cada grupo. (Cupos
                    limitados)
                  </li>
                  <li>
                    <b>
                      Formaciones en habilidades innovadoras de comunicación:
                    </b>{" "}
                    storytelling, comunicación incluyente, comunicación digital,
                    entre otros temas. (Cupos limitados){" "}
                  </li>
                  <li>
                    <b>Pensamiento de vanguardia:</b> invitamos a expertas de
                    todas partes del mundo a inspirarnos y compartir sus
                    secretos para construir poder narrativo. (Cupos ilimitados)
                  </li>
                </ol>
              </li>
              <li>
                <ol>
                  <b>2. Investigación:</b> recabamos resultados que resultan de
                  la investigación de audiencias y narrativas a partir de
                  encuestas, análisis de redes y grupos focales para ponerlos a
                  disposición de las organizaciones de forma tal que puedan
                  refinar sus estrategias de comunicación.
                  <li>
                    <b>Estudios de audiencias:</b> Para construir poder
                    narrativo de manera efectiva es necesario conocer a nuestras
                    audiencias. Por eso, ponemos a disposición de las
                    organizaciones los resultados de las encuestas e
                    investigaciones de audiencias que realizamos con Sensata.
                    (Cupos ilimitados)
                  </li>
                  <li>
                    <b>Investigación digital:</b> En colaboración con Linterna
                    verde ofrecemos comunidades de investigación a través del
                    proyecto de la Lupa Digital. Cada comunidad recibirá
                    boletines mensuales que permitirán ampliar el entendimiento
                    del debate público digital; pensar en narrativas digitales
                    que potencien su influencia; y fortalecer la capacidad de
                    reacción ante ataques, manipulación o campañas de
                    coordinación en redes sociales y plataformas de internet.
                    (Cupos ilimitados)
                  </li>
                  <li>
                    <b>Grupos focales:</b> Ocasionalmente, también ofrecemos
                    realizar grupos focales con Untold Research para entender de
                    manera más cualitativa a determinadas audiencias, o para
                    probar la eficacia de ciertos mensajes. (Cupos limitados)
                  </li>
                </ol>
              </li>
              <li>
                <ol>
                  <b>3. Experimentación narrativa:</b> En casos puntuales,
                  lanzaremos convocatorias dirigidas a organizaciones que ya se
                  hayan formado en el nuevo paradigma de las comunicaciones para
                  recibir acompañamientos individuales y/o becas de creación.
                  <li>
                    <b>Narremos la Utopía:</b> Damos becas de creación para
                    experimentar con la comunicación basada en la evidencia, las
                    historias para la esperanza, y el desarrollo de narrativas
                    interseccionales. Nuestro objetivo es promover la creación
                    colectiva de narrativas, para cambiar la cultura y hacer más
                    fácil y sostenible el cambio político que buscamos. (Cupos
                    limitados).
                  </li>
                  <li>
                    <b>Acompañamientos individuales:</b> Las organizaciones de
                    México y Centroamérica que hayan participado en alguna de
                    nuestras formaciones sobre el nuevo paradigma de las
                    comunicaciones podrán aplicar a las convocatorias para
                    recibir acompañamientos individuales que les permitan
                    resolver alguna necesidad comunicacional e incorporar los
                    elementos del nuevo paradigma al trabajo de su organización.
                    (Cupos limitados)
                  </li>
                </ol>
              </li>
            </ServicesOl>
          </ServiceTitle>
        </AnimationOnScroll>
      )}
      {isActive === 2 && (
        <AnimationOnScroll animateIn="animate__fadeInRightBig">
          <ServiceTitle>
            <ServicesH2C>
              Queremos contribuir a la resiliencia de las organizaciones, la
              disminución de vulnerabilidades legales, y la continuidad de su
              proyecto de cambio social. A partir de las necesidades concretas
              de las organizaciones de la sociedad civil en cuestiones de
              cumplimiento fiscal, laboral, corporativo, internacional o
              administrativo, ofrecemos servicios que fortalezcan su capacidad
              de respuesta ante los constantes cambios del marco legal. Para
              lograrlo, tomamos en cuenta las características del sector y el
              contexto político regional.
            </ServicesH2C>

            <ServicesOl2>
              <ServicesH2C>
                <b>Asistimos a las organizaciones en materia de:</b>{" "}
              </ServicesH2C>
              <li>
                <b>Cumplimiento legal fiscal:</b> nuestros servicios parten del
                marco regulatorio que tienen los países para considerar a las
                organizaciones de la sociedad civil como entidades sin fines de
                lucro y hacerlo compatible con su operación financiera.
              </li>
              <li>
                <b>Cumplimiento legal laboral:</b> entendemos a las
                organizaciones como centros de trabajo, y por tanto, sujetas a
                obligaciones patronales y al respeto de los derechos laborales
                de quienes trabajan en ellas.
              </li>
              <li>
                <b>Cumplimiento legal corporativo:</b> partimos de la necesidad
                de entender a las organizaciones como entes sujetos al
                cumplimiento de distintos estándares de gobernanza y
                obligaciones societarias.
              </li>
              <li>
                <b>Cumplimiento legal internacional:</b> nuestras propuestas
                parten del entendimiento del impacto del marco regulatorio
                internacional en la operación de las organizaciones de la
                sociedad civil.
              </li>
              <li>
                <b>Cumplimiento legal administrativo:</b> a partir de la
                implementación de cualquiera de los servicios de cumplimiento
                anteriores, desarrollamos estrategias de adaptación para la
                operación cotidiana y los procesos internos de la organización.
              </li>
            </ServicesOl2>
            <ServicesH2C>
              Todos estos servicios podrán ofrecerse a través de materiales de
              acceso universal, como servicios colectivos o individuales, de
              acuerdo con la particularidad y profundidad que cada uno amerite.
            </ServicesH2C>
          </ServiceTitle>
        </AnimationOnScroll>
      )}
      {isActive === 3 && (
        <AnimationOnScroll animateIn="animate__fadeInLeftBig">
          <ServiceTitle>
            <ServicesH2C>
              Ofrecemos acompañamientos que buscan fortalecer la capacidad de
              adaptación estratégica y resiliencia institucional de
              organizaciones de la sociedad civil comprometidas con la
              transformación social, para que puedan aumentar su potencial de
              impacto y alcanzar los cambios que se proponen. Estos
              acompañamientos se ofrecen de forma individual, sobre la base de
              un diagnóstico institucional, y en el proceso se atienden las
              necesidades concretas y las circunstancias específicas de cada
              organización.
            </ServicesH2C>
            <ServicesH2C>
              <b>Acompañamiento en pensamiento estratégico</b>{" "}
            </ServicesH2C>
            <ServicesH2C>
              Los procesos para desarrollar el pensamiento estratégico son
              esenciales para la resiliencia institucional y la adaptación de
              las organizaciones, redes y colectivas de sociedad civil, ya que
              las dota de herramientas que les permiten priorizar y actuar para
              lograr el impacto deseado y acercarse al cumplimiento de su misión
              institucional.
            </ServicesH2C>
            <ServicesH2C>
              Este acompañamiento requiere espacios y procesos de reflexión
              facilitados para la toma de decisiones estratégicas, así como para
              el análisis de contexto, logros, áreas de oportunidad y valor
              agregado de la organización. Según las necesidades de las
              organizaciones, el proceso puede tener varias etapas. La etapa
              central es el desarrollo de una Teoría de Cambio (TOC), misma que
              permite definir objetivos de largo, mediano y corto plazo, y que
              posibilita planear con visión de impacto. Eventualmente, de cara a
              la implementación de una TOC, los acompañamientos podrán construir
              planes de trabajo e indicadores de monitoreo, evaluación y
              aprendizaje.
            </ServicesH2C>
            <ServicesH2C>
              <b>Acompañamiento en resiliencia financiera</b>{" "}
            </ServicesH2C>
            <ServicesH2C>
              Los retos que las organizaciones enfrentan no siempre están
              relacionados a sus estrategias sustantivas, sino con la
              precariedad de sus plataformas operativas y la estrecha relación
              de estas con estrategias de presupuestación, seguimiento y manejo
              financiero que les permitan retener talento, innovar en recursos
              de conocimiento o planear para el futuro.
            </ServicesH2C>
            <ServicesH2C>
              Este acompañamiento busca construir habilidades y conocimientos
              sostenibles para que la operación y movilización de recursos sea
              el centro de una estrategia institucional de sostenibilidad e
              impacto. Para ello, se trabaja con las organizaciones en sesiones
              enfocadas en cinco aspectos clave de la gestión de recursos
              financieros: presupuestación, diversificación, contexto de
              financiamiento, liderazgo y comunicación.
            </ServicesH2C>
            <ServicesH2C>
              Nuestro acompañamiento brinda procesos participativos de reflexión
              y ejercicios prácticos para detectar, diseñar e implementar
              acciones que contribuyan a la sostenibilidad y a la resiliencia
              financiera de las organizaciones, considerando la vinculación de
              los equipos programáticos y administrativos, y el fortalecimiento
              de sus capacidades para definir las necesidades financieras, así
              como la movilización y gestión de recursos.
            </ServicesH2C>
          </ServiceTitle>
        </AnimationOnScroll>
      )}
      {isActive === 4 && (
        <AnimationOnScroll animateIn="animate__fadeInRightBig">
          <ServiceTitle>
            <ServicesH2C>
              Nuestro concepto de protección integral se aleja de las visiones
              tradicionales de la seguridad, comúnmente vinculadas al control
              social o al recorte de derechos civiles. Apostamos por una mirada
              apegada al resguardo y la protección de los derechos humanos,
              atendiendo distintos aspectos, como la seguridad física, la
              seguridad psicosocial, la seguridad legal y la seguridad digital,
              entre otros. Con ello, buscamos mejorar el bienestar colectivo en
              las organizaciones de la sociedad civil. Ofrecemos:
            </ServicesH2C>
            <ServicesOl2>
              <li>
                <ServicesH2C>
                  <b>
                    Acompañamiento para el fortalecimiento de la resiliencia en
                    protección integral colectiva
                  </b>{" "}
                </ServicesH2C>
                <ServicesH2C>
                  Se trata de un proceso de acompañamiento dirigido a
                  organizaciones, colectivos y redes. Incluye una etapa de
                  análisis de riesgo, así como un proceso de capacitación que
                  abarca los diferentes ámbitos que conforman la protección
                  integral.
                </ServicesH2C>
              </li>
              <li>
                <ServicesH2C>
                  <b>Procesos formativos virtuales</b>{" "}
                </ServicesH2C>
                <ServicesH2C>
                  Los procesos formativos están dirigidos a personas defensoras
                  de derechos humanos en México y Centroamérica. La oferta
                  incluye formación en diferentes temas, como la seguridad
                  digital, acompañamiento psicosocial o nuevas masculinidades.
                </ServicesH2C>
              </li>
              <li>
                <ServicesH2C>
                  <b>Acompañamiento y asesoría en seguridad digital</b>{" "}
                </ServicesH2C>
                <ServicesH2C>
                  Está dirigido a organizaciones. Este acompañamiento está
                  conformado por un diagnóstico inicial, y un ciclo de atención
                  y soporte a partir de las necesidades identificadas en el
                  diagnóstico.
                </ServicesH2C>
              </li>
              <li>
                <ServicesH2C>
                  <b>Fondos para la protección integral</b>{" "}
                </ServicesH2C>
                <ServicesH2C>
                  Se trata de pequeños fondos dirigidos tanto a organizaciones
                  como a personas defensoras de derechos humanos. Los fondos
                  para organizaciones tienen el objetivo de financiar acciones
                  colectivas de protección integral; mientras que los fondos
                  individuales buscan cubrir necesidades de protección integral
                  de las personas defensoras.
                </ServicesH2C>
              </li>
              <li>
                <ServicesH2C>
                  <b>Reubicación integral en Costa Rica</b>{" "}
                </ServicesH2C>
                <ServicesH2C>
                  Es un espacio de descanso y desconexión para las personas
                  defensoras de derechos humanos en Centroamérica que se
                  encuentran en situación de riesgo. Tiene el objetivo de
                  propiciar un espacio seguro para su cuidado personal. Durante
                  el periodo de reubicación se acompaña a las personas en el
                  fortalecimiento de capacidades de protección integral.
                  Asimismo, las personas defensoras tienen acceso a actividades
                  de salud holística, cursos, intercambios con otras
                  organizaciones pares y actividades culturales, entre otras.
                </ServicesH2C>
              </li>
            </ServicesOl2>
          </ServiceTitle>
        </AnimationOnScroll>
      )}
      <SendButton>
        <ServicesLinkA to={`/solicitud_de_servicios${isActive ? `?refererID=${isActive}` : ''}`}>
          SOLICITUD DE SERVICIOS
          <img src={flecha} alt="Enviar Solicitud" />
        </ServicesLinkA>
      </SendButton>
    </SubContainerA>
  );
};

export default ServicesPage;
